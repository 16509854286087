import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function Blurb({ title, children }) {
  return (
    <section className="blurb">
      <div className="container">
        <div>
          <h1>{title}</h1>
          {children}
        </div>
        <div>
          <StaticImage
            src="../../content/images/dinever-avatar.png"
            alt="dinever"
            className="avatar"
            placeholder="blurred"
          />
        </div>
      </div>
    </section>
  )
}
